import { Redirect, Route, Switch } from "react-router-dom";
import { H3 } from "../../../components/Heading";
import { ErrorCheckingSettings } from "./error_checking";
import { DealRoleRestricted } from "../../../components/DealRoleRestricted";
import { DealRole } from "../../../graphql/generated";
import { DealExports } from "./exports";

export function DealSettings() {
  return (
    <div className="flex-1">
      <DealRoleRestricted
        sellerRunDealRoles={[DealRole.BuyerAdmin]}
        buyerRunDealRoles={[DealRole.SellerAdmin]}
      >
        <div className="bg-white z-20 px-8 py-6 w-full shadow-sm border-b border-gray-300/80">
          <div className="flex items-center justify-between">
            <div>
              <H3>Access</H3>
            </div>
          </div>
        </div>
      </DealRoleRestricted>
      <div className="p-8 pt-4">
        <Switch>
          <Route path="/deal/settings" exact>
            <Redirect to="/deal/settings/exports" />
          </Route>
          <Route path="/deal/settings/error-checking">
            <ErrorCheckingSettings />
          </Route>
          <Route path="/deal/settings/exports">
            <DealExports />
          </Route>
        </Switch>
      </div>
    </div>
  );
}
