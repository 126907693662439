import { NavLink, useParams } from "react-router-dom";
import {
  useDealFirmGroupQuestionsQuery,
  Question as GqlQuestion,
  DdqlStatus,
  Ddql,
  DealRole,
} from "../../graphql/generated";
import useGqlClient from "../../hooks/useGqlClient";
import { useSelector } from "react-redux";
import { authSelectors } from "../../store/auth/selector";
import Loading from "../../components/Loading";
import { H4 } from "../../components/Heading";
import { PageContent } from "../../components/PageContent";
import { Card } from "../../components/Card";
import { NewQuestionModal, Question } from ".";
import {
  ArrowDownTrayIcon,
  ClipboardDocumentListIcon,
  PlusIcon,
} from "@heroicons/react/20/solid";
import { Button } from "../../components/tailwind/Button";
import { useState } from "react";
import { ImportDDQLModal } from "./ImportDDQLModal";
import { Spinner } from "../../components/icons/Spinner";
import { useQueryClient } from "@tanstack/react-query";
import { formatDistanceToNow, fromUnixTime } from "date-fns";
import { classNames } from "../../utils/cn";
import { LocalFileIcon } from "../../components/icons/LocalFileIcon";
import { Pills } from "../../components/Pills";
import {
  allRoles,
  DealRoleRestricted,
} from "../../components/DealRoleRestricted";

export function Group() {
  const { id } = useParams<{ id: string }>();

  const queryClient = useQueryClient();
  const client = useGqlClient();
  const activeDealId = useSelector(authSelectors.activeDealId);
  const query = useDealFirmGroupQuestionsQuery(client, {
    id: id,
  });

  const account = useSelector(authSelectors.account);

  const [openModal, setOpenModal] = useState<
    "" | "create_ddql" | "new_question"
  >("");

  if (query.error) {
    return (
      <div className="flex-1 flex w-full h-full justify-center items-center">
        <p className="font-semibold text-sm text-gray-700">
          Something went wrong
        </p>
      </div>
    );
  }

  if (query.isPending || !query.data) {
    return <Loading />;
  }

  return (
    <div className="flex-1 flex-col flex">
      <div className="bg-white px-8 py-3 w-full border-b border-gray-300/80">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-x-2">
            <NavLink to="/questions">
              <h4 className="font-bold text-gray-400 hover:text-gray-700">
                Questions
              </h4>
            </NavLink>
            <p className="font-light text-gray-400">/</p>
            <H4>{query.data.dealFirmGroup.name}</H4>
          </div>
          <div></div>
        </div>
      </div>

      {query.data.dealFirmGroup.questions.length === 0 ? (
        <div className="flex justify-center items-center flex-1 ">
          <div>
            <p className="text-center font-semibold text-gray-800 ">
              No questions yet
            </p>
            <p className="text-gray-500/80 text-sm text-center">
              We'll notify you when a new question is asked
            </p>
          </div>
        </div>
      ) : (
        <PageContent>
          <div className=" w-1/2">
            <H4>Questions</H4>
            <Card margin="s 0 0 0">
              {query.data.dealFirmGroup.questions
                .sort((a, b) => {
                  if (a.answer && !b.answer) {
                    return 1;
                  }

                  if (!a.answer && b.answer) {
                    return -1;
                  }

                  return 0;
                })
                .map((question, i) => {
                  return (
                    <>
                      <Question
                        key={question.id}
                        question={question as GqlQuestion}
                        isFirst={i === 0}
                        isLast={
                          i === query.data.dealFirmGroup.questions.length - 1
                        }
                      />
                      {i !== query.data.dealFirmGroup.questions.length - 1 ? (
                        <div className="border-b border-gray-200" />
                      ) : null}
                    </>
                  );
                })}
            </Card>

            <div className="mt-12 flex justify-between">
              <H4>Question list imports</H4>
              <button
                onClick={() => {
                  setOpenModal("create_ddql");
                }}
                className="text-persian-600 text-sm font-semibold flex items-center gap-x-1 hover:text-persian-800"
              >
                <ArrowDownTrayIcon className="w-4 h-4 " />
                Import question list
              </button>
            </div>
            {query.data.dealFirmGroup.ddqls.length === 0 ? (
              <Card margin="s 0 0 0" padding="m">
                <div className="flex flex-col justify-center items-center flex-1 ">
                  <ClipboardDocumentListIcon className="h-8 w-8 text-gray-400" />
                  <p className="text-sm text-center font-semibold text-gray-800 ">
                    No imports yet
                  </p>
                  <p className="text-gray-500 text-xs text-center">
                    We'll notify you when{" "}
                    <span className="font-medium">
                      {query.data.dealFirmGroup.name}
                    </span>{" "}
                    uploads a new question list
                  </p>
                  <Button
                    variant="neutral"
                    icon={ArrowDownTrayIcon}
                    text="Import question list"
                    size="s"
                    margin="m 0 0 0"
                    onClick={() => {
                      setOpenModal("create_ddql");
                    }}
                  />
                </div>
              </Card>
            ) : (
              <Card margin="s 0 0 0">
                {query.data.dealFirmGroup.ddqls.map((ddql, i) => {
                  return (
                    <NavLink
                      key={i}
                      to={`/questions/group/${id}/ddql/${ddql.id}`}
                    >
                      <div
                        className={classNames(
                          "flex items-center justify-between hover:bg-gray-100 p-3",
                          i === 0 ? "rounded-t-md" : "",
                          i === query.data.dealFirmGroup.ddqls.length - 1
                            ? "rounded-b-md"
                            : ""
                        )}
                      >
                        <div className="">
                          <p className="text-sm font-bold text-gray-700">
                            {ddql.name}
                          </p>
                          <p className="text-xs text-gray-500/80">
                            Created{" "}
                            {formatDistanceToNow(fromUnixTime(ddql.createdAt), {
                              addSuffix: true,
                            })}
                          </p>
                          <div className="mt-2">
                            <Pills>
                              <StatusPill mode="compact" ddql={ddql} />
                            </Pills>
                          </div>
                        </div>
                        <div className="w-52 flex items-center gap-x-2">
                          <ProgressBar
                            progress={
                              ddql.questions.length === 0
                                ? 0
                                : (ddql.questions.filter((q) => q.answer)
                                    .length /
                                    ddql.questions.length) *
                                  100
                            }
                          />
                          <p className="text-xs text-gray-500/80">
                            {ddql.questions.filter((q) => q.answer).length}/
                            {ddql.questions.length}
                          </p>
                        </div>
                      </div>
                    </NavLink>
                  );
                })}
              </Card>
            )}
            <div className="mt-12 w-full h-px bg-gray-200 " />
            <div className="mt-3 bg-yellow-50 p-2 border-yellow-300 border rounded-md">
              <div className="flex justify-between items-center">
                <H4>Questions for {query.data.dealFirmGroup.name}</H4>
                <DealRoleRestricted
                  buyerRunDealRoles={[...allRoles]}
                  sellerRunDealRoles={[
                    DealRole.DealAdmin,
                    DealRole.DealOwner,
                    DealRole.SellerAdmin,
                    DealRole.SellerStaff,
                  ]}
                >
                  <button
                    onClick={() => {
                      setOpenModal("new_question");
                    }}
                    className="text-sm font-semibold text-persian-600 hover:text-persian-800 flex items-center"
                  >
                    <PlusIcon className="w-4 h-4 " />
                    Add question
                  </button>
                </DealRoleRestricted>
              </div>
              {query.data.dealFirmGroup.questionsForGroup.length === 0 ? (
                <Card padding="m" margin="s 0 0 0">
                  <p className="font-semibold text-gray-600 text-sm text-center">
                    {query.data.dealFirmGroup.name} hasn't been asked any
                    questions yet
                  </p>
                </Card>
              ) : (
                <Card margin="s 0 0 0">
                  {query.data.dealFirmGroup.questionsForGroup
                    .sort((a, b) => {
                      if (a.answer && !b.answer) {
                        return 1;
                      }

                      if (!a.answer && b.answer) {
                        return -1;
                      }

                      return 0;
                    })
                    .map((question, i) => {
                      return (
                        <Question
                          isFirst={i === 0}
                          isLast={
                            i ===
                            query.data.dealFirmGroup.questionsForGroup.length -
                              1
                          }
                          key={question.id}
                          question={question as GqlQuestion}
                        />
                      );
                    })}
                </Card>
              )}
            </div>
          </div>
        </PageContent>
      )}
      <ImportDDQLModal
        open={openModal === "create_ddql"}
        onClose={() => {
          queryClient.invalidateQueries({
            queryKey: ["DealFirmGroupQuestions", { id: id }],
          });
          setOpenModal("");
        }}
        dealFirmGroupId={id}
      />
      <NewQuestionModal
        open={openModal === "new_question"}
        onClose={() => setOpenModal("")}
        forDealFirmGroupId={id}
      />
    </div>
  );
}

export function StatusPill(props: {
  ddql: Pick<Ddql, "status" | "sourceFileName" | "sourceFileType">;
  mode?: "compact" | "default";
}) {
  switch (props.ddql.status) {
    case DdqlStatus.Imported:
      return (
        <div className="rounded-full -space-x-1 px-2 py-1 border  border-gray-400 flex items-center">
          <div className="flex items-center">
            {props.mode === "compact" ? null : (
              <p className="font-semibold text-xs text-gray-700 mr-2">
                Imported from
              </p>
            )}
            <LocalFileIcon size="s" fileType={props.ddql.sourceFileType} />
            <p className="ml-0.5 text-xs text-gray-700">
              {props.ddql.sourceFileName}
            </p>
          </div>
        </div>
      );
    case DdqlStatus.Importing:
      return (
        <div className="rounded-full -space-x-1 px-2 py-1 border  border-gray-400 flex items-center">
          <Spinner size="xs" color="gray" />
          <div className="flex items-center">
            <p className="font-semibold text-xs text-gray-700 mr-2">
              Importing from
            </p>
            <LocalFileIcon size="s" fileType={props.ddql.sourceFileType} />
            <p className="ml-0.5 text-xs text-gray-700">
              {props.ddql.sourceFileName}
            </p>
          </div>
        </div>
      );
    case DdqlStatus.ImportFailed:
      return (
        <div className="rounded-full px-2 py-1 border bg-red-50 border-red-700">
          <p className="font-semibold text-xs text-red-700">Failed to import</p>
        </div>
      );
    default:
      return null;
  }
}

function ProgressBar(props: { progress: number }) {
  return (
    <div className="h-1 w-full bg-green-500/20 rounded-md">
      <div
        className="h-1 bg-green-500 rounded-md transition-all duration-300 ease-in-out"
        style={{ width: `${props.progress}%` }}
      ></div>
    </div>
  );
}
