import React, { Fragment, useEffect, useState } from "react";
import { Card } from "../../components/Card";
import { H3 } from "../../components/Heading";
import Loading from "../../components/Loading";
import { formatDistanceToNowStrict, fromUnixTime, getUnixTime } from "date-fns";
import {
  DataRoomFolderFragmentFragment,
  DataRoomFolderQuery,
  DataRoomPermission,
  useDataRoomFolderQuery,
  useDataRoomQuery,
  useUpdateDataRoomFolderMutation,
  useDealActivityQuery,
  DealRunner,
  FileType,
  DataRoomFileOnlineStatus,
} from "../../graphql/generated";
import useGqlClient from "../../hooks/useGqlClient";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import {
  EllipsisVerticalIcon,
  ExclamationCircleIcon,
  FolderIcon,
} from "@heroicons/react/24/solid";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { useQueryClient } from "@tanstack/react-query";
import { FileIcon } from "../../components/FileIcon";
import { DeleteFile } from "./DeleteFile";
import { DataRoomHeader } from "../../components/data_room/DataRoomHeader";
import { EditFile } from "../../components/data_room/EditFile";
import { useSelector } from "react-redux";
import { authSelectors } from "../../store/auth/selector";
import { AnimatedModal } from "../../components/AnimatedModal";
import { FolderOpenIcon } from "@heroicons/react/20/solid";
import {
  DealRoleRestricted,
  adminRoles,
  allAdmins,
  allRoles,
  sellSideAdmins,
  sellerRoles,
} from "../../components/DealRoleRestricted";
import { ActivityItems } from "../../components/activity/ActivityItems";
import { MoveFileModal } from "./MoveFileModal";
import { toasts } from "../../components/toasts/toasts";
import { Pills } from "../../components/Pills";
import { Tooltip } from "../../components/Tooltip";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

interface Key {
  icon: React.ReactNode;
  name: string;
  tooltip?: string;
}

export function DataRoom() {
  const client = useGqlClient();
  const activeDealId = useSelector(authSelectors.activeDealId);
  const dataRoomQuery = useDataRoomQuery(client, {
    dealId: activeDealId ? activeDealId : "",
  });

  const { folderId } = useParams<{ folderId: string }>();

  if (!dataRoomQuery.data || dataRoomQuery.isPending) {
    return <Loading />;
  }

  if (dataRoomQuery.error) {
    return <div>Something went wrong</div>;
  }

  const keys: Key[] =
    dataRoomQuery.data.deal.runner === DealRunner.Seller
      ? [
          {
            icon: <FolderIcon className="h-5 w-5 text-orange-700/70" />,
            name: "Buyer folder",
            tooltip:
              "Every buyer gets their own folder where they can upload files and create folders",
          },
          {
            icon: <FolderIcon className="h-5 w-5 text-blue-700/70" />,
            name: "Normal folder",
          },
          {
            icon: <FileIcon fileType={FileType.Pdf} />,
            name: "PDF",
          },
          {
            icon: <FileIcon fileType={FileType.Xlsx} />,
            name: "Excel",
          },
          {
            icon: <FileIcon fileType={FileType.Docx} />,
            name: "Word",
          },
          {
            icon: <FileIcon fileType={FileType.Pptx} />,
            name: "Powerpoint",
          },
          {
            icon: <FileIcon fileType={FileType.Other} />,
            name: "Unknown",
          },
        ]
      : [
          {
            icon: <FileIcon fileType={FileType.Pdf} />,
            name: "PDF",
          },
          {
            icon: <FileIcon fileType={FileType.Xlsx} />,
            name: "Excel",
          },
          {
            icon: <FileIcon fileType={FileType.Docx} />,
            name: "Word",
          },
          {
            icon: <FileIcon fileType={FileType.Pptx} />,
            name: "Powerpoint",
          },
          {
            icon: <FileIcon fileType={FileType.Other} />,
            name: "Unknown",
          },
        ];

  return (
    <div className="flex-1">
      <DataRoomHeader
        folderId={
          folderId ? folderId : dataRoomQuery.data.deal.dataRoom.folder.id
        }
        permissions={dataRoomQuery.data.deal.dataRoom.permissions}
        recentSearchesKey={dataRoomQuery.data.deal.id}
        dataRoomId={dataRoomQuery.data.deal.dataRoom.id}
        root="deal/documents"
        title="Documents"
      />
      <div className="p-8 pt-4">
        <Folder
          id={folderId ? folderId : dataRoomQuery.data.deal.dataRoom.folder.id}
          permissions={dataRoomQuery.data.deal.dataRoom.permissions}
        />
        <Pills>
          {keys.map((key) => {
            return (
              <div
                key={key.name}
                className="rounded-md border border-gray-200 gap-x-2 bg-white  px-2 py-1 flex items-center"
              >
                {key.icon}
                <p className="text-sm text-gray-700 mt-0.5 font-medium">
                  {key.name}
                </p>
                {key.tooltip && <Tooltip text={key.tooltip} />}
              </div>
            );
          })}
        </Pills>
        <DealRoleRestricted
          sellerRunDealRoles={[...sellerRoles, ...adminRoles]}
          buyerRunDealRoles={allRoles}
        >
          <div className="grid md:grid-cols-2 mt-8 gap-8">
            <div>
              <div className="flex justify-between items-center">
                <H3 margin="0">Recent activity</H3>
                <Link to="/deal/documents/activity">
                  <p className="text-xs font-semibold text-blue-500">
                    View all
                  </p>
                </Link>
              </div>
              <Activity
                dataRoomId={dataRoomQuery.data.deal.dataRoom.id}
                dataRoomFolderId={folderId}
              />
            </div>
            <div>
              <FolderMeta folderId={folderId} />
            </div>
          </div>
        </DealRoleRestricted>
      </div>
    </div>
  );
}

function FolderMeta(props: { folderId: string }) {
  const client = useGqlClient();
  const folderQuery = useDataRoomFolderQuery(client, {
    id: props.folderId,
  });

  if (folderQuery.isPending) {
    return null;
  }

  if (folderQuery.error || !folderQuery.data) {
    return null;
  }

  if (!folderQuery.data.dataRoomFolder.dealFirmGroup) {
    return null;
  }

  return (
    <Card padding="m">
      <div className="flex items-center gap-x-2">
        <FolderIcon className="h-6 w-6 text-orange-700/70" />
        <p className="font-semibold text-gray-700 mt-1">Buyer folder</p>
      </div>
      <p className="text-sm text-gray-500 mt-2">
        This is a buyer folder for{" "}
        <span className="font-semibold">
          {folderQuery.data.dataRoomFolder.dealFirmGroup.name}
        </span>
        .<br />
        They can manage files and folders in this folder.
      </p>
    </Card>
  );
}

interface FolderProps {
  id: string;
  permissions: DataRoomPermission[];
}

function Folder(props: FolderProps) {
  const client = useGqlClient();
  const history = useHistory();
  const query = useQuery();
  const location = useLocation();
  const folderQuery = useDataRoomFolderQuery(client, {
    id: props.id,
  });

  return (
    <>
      <Card>
        <div className="h-96 overflow-y-scroll no-scrollbar">
          <Files
            isLoading={folderQuery.isPending}
            error={folderQuery.error}
            folder={
              folderQuery.data ? folderQuery.data.dataRoomFolder : undefined
            }
          />
        </div>
      </Card>

      <DeleteFolder
        open={
          query.get("deleteFolder") !== null && query.get("deleteFolder") !== ""
        }
        onClose={() => {
          history.replace({ pathname: location.pathname, search: "" });
        }}
      />
    </>
  );
}

interface FilesProps {
  folder?: DataRoomFolderFragmentFragment;
  isLoading: boolean;
  error?: unknown;
}

function Files(props: FilesProps) {
  const history = useHistory();
  const location = useLocation();

  const [deleteFileId, setDeleteFileId] = useState<string>("");
  const [editFileId, setEditFileId] = useState<string>("");
  const [moveFileId, setMoveFileId] = useState<string>("");

  if (props.error) {
    return (
      <div>
        <p>Something went wrong</p>
      </div>
    );
  }

  if (props.isLoading || !props.folder) {
    return <Loading />;
  }

  if (props.folder.files.length === 0 && props.folder.folders.length === 0) {
    return (
      <div className="h-96 flex justify-center items-center flex-col">
        <FolderOpenIcon className="h-12 w-12 text-gray-500/70" />
        <p className="m-0 font-semibold text-gray-700">
          You haven't uploaded any files yet
        </p>
        <p className="text-sm text-gray-500">
          Files and folders you create will appear here
        </p>
      </div>
    );
  }

  return (
    <>
      <table className="table-fixed w-full box-border text-left border-collapse block">
        <tbody className="w-full table">
          <tr>
            <th
              className="text-sm py-2 px-3 text-gray-500/80  truncate"
              style={{ width: "100%" }}
            >
              Name
            </th>
            <DealRoleRestricted
              sellerRunDealRoles={[...sellerRoles, ...adminRoles]}
              buyerRunDealRoles={allRoles}
            >
              <th
                className="text-sm py-2 px-3 text-gray-500/80 hidden xl:table-cell truncate"
                style={{ minWidth: 140 }}
              >
                Created by
              </th>
            </DealRoleRestricted>
            <th
              className="text-sm py-2 px-3 text-gray-500/80 hidden xl:table-cell truncate"
              style={{ minWidth: 130 }}
            >
              Last updated
            </th>
            <DealRoleRestricted
              sellerRunDealRoles={[...sellerRoles, ...adminRoles]}
              buyerRunDealRoles={[]}
            >
              <th
                className="text-sm py-2 px-3 text-gray-500/80 hidden xl:table-cell truncate"
                style={{ minWidth: 130 }}
              >
                Access level
              </th>
              <th className="py-1 px-2 w-55 text-gray-500 hidden xl:table-cell"></th>
            </DealRoleRestricted>
          </tr>
          {props.folder.folders
            .sort((a, b) => {
              if (a.dealFirmGroup && !b.dealFirmGroup) {
                return -1;
              }

              if (!a.dealFirmGroup && b.dealFirmGroup) {
                return 1;
              }

              return 0;
            })
            .map((folder) => {
              return (
                <tr
                  key={folder.id}
                  className="cursor-pointer hover:bg-gray-50 w-full table-row  group"
                  onClick={() => {
                    history.push(`/deal/documents/folder/` + folder.id);
                  }}
                >
                  <td className="w-full py-2 px-3">
                    <div className="flex items-center gap-x-2">
                      <FolderIcon
                        className={
                          folder.dealFirmGroup
                            ? "text-orange-800/70"
                            : "text-blue-700/70"
                        }
                        width={20}
                        height={20}
                      />
                      <p className="text-sm font-semibold text-gray-600">
                        {folder.name}
                      </p>
                    </div>
                  </td>
                  <DealRoleRestricted
                    sellerRunDealRoles={[...sellerRoles, ...adminRoles]}
                    buyerRunDealRoles={allRoles}
                  >
                    <td className="py-2 px-3 hidden xl:table-cell truncate">
                      <p className="text-sm text-gray-500">
                        {folder.createdBy.name}
                      </p>
                    </td>
                  </DealRoleRestricted>
                  <td className="py-2 px-3 hidden xl:table-cell">
                    <p className="text-sm text-gray-500">
                      {formatDistanceToNowStrict(
                        fromUnixTime(folder.createdAt),
                        {
                          addSuffix: true,
                        }
                      )}
                    </p>
                  </td>
                  <DealRoleRestricted
                    sellerRunDealRoles={[...sellerRoles, ...adminRoles]}
                    buyerRunDealRoles={[]}
                  >
                    <td className="py-2 px-3 hidden xl:table-cell">
                      <p className="text-sm text-gray-500">
                        {folder.permission ? folder.permission.name : "-"}
                      </p>
                    </td>
                  </DealRoleRestricted>
                  <DealRoleRestricted
                    sellerRunDealRoles={sellSideAdmins}
                    buyerRunDealRoles={allAdmins}
                  >
                    <td className="py-2 px-3 hidden xl:table-cell">
                      <Menu as="div" className="relative flex-none">
                        <Menu.Button
                          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                            e.stopPropagation();
                          }}
                          className="opacity-0 group-hover:opacity-100 -m-2.5 block p-2.5 text-gray-500 hover:text-gray-900"
                        >
                          <span className="sr-only">Open options</span>
                          <EllipsisVerticalIcon
                            className="h-5 w-5"
                            aria-hidden="true"
                          />
                        </Menu.Button>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                            <Menu.Item>
                              {({ active }) => (
                                <div
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    const params = new URLSearchParams({
                                      deleteFolder: folder.id,
                                    });
                                    history.replace({
                                      pathname: location.pathname,
                                      search: params.toString(),
                                    });
                                  }}
                                  className={classNames(
                                    active ? "bg-gray-50 text-red-700" : "",
                                    "block px-3 py-1 text-sm leading-6 text-red-600 cursor-pointer"
                                  )}
                                >
                                  Delete
                                </div>
                              )}
                            </Menu.Item>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </td>
                  </DealRoleRestricted>
                </tr>
              );
            })}
          {props.folder.files
            .sort((a, b) => {
              if (
                a.onlineStatus === DataRoomFileOnlineStatus.Online &&
                b.onlineStatus !== DataRoomFileOnlineStatus.Online
              ) {
                return -1;
              }

              if (
                b.onlineStatus === DataRoomFileOnlineStatus.Online &&
                a.onlineStatus !== DataRoomFileOnlineStatus.Online
              ) {
                return 1;
              }

              return 0;
            })
            .map((file) => {
              return (
                <tr
                  key={file.id}
                  className={`cursor-pointer hover:bg-gray-50 w-full table-row  group ${
                    file.onlineStatus === DataRoomFileOnlineStatus.Online
                      ? ""
                      : "opacity-70"
                  }`}
                  onClick={() => {
                    if (!props.folder) {
                      return;
                    }
                    const link = `/deal/documents/folder/${props.folder.id}/file/${file.id}`;
                    history.push(link);
                  }}
                >
                  <td className="py-2 px-3">
                    <div className="flex items-center gap-x-2">
                      <FileIcon fileType={file.fileType} />
                      <p className="text-gray-600 font-semibold text-sm">
                        {file.name}
                      </p>
                      {file.onlineStatus ===
                      DataRoomFileOnlineStatus.Offline ? (
                        <p className="text-xs text-gray-500">Offline</p>
                      ) : null}
                    </div>
                  </td>
                  <DealRoleRestricted
                    sellerRunDealRoles={[...sellerRoles, ...adminRoles]}
                    buyerRunDealRoles={allRoles}
                  >
                    <td className="py-2 px-3 hidden xl:table-cell">
                      <p className="text-sm text-gray-500">
                        {file.createdBy.name}
                      </p>
                    </td>
                  </DealRoleRestricted>
                  <td className="py-2 px-3 hidden xl:table-cell">
                    <p className="text-sm text-gray-500">
                      {formatDistanceToNowStrict(fromUnixTime(file.updatedAt), {
                        addSuffix: true,
                      })}
                    </p>
                  </td>
                  <DealRoleRestricted
                    sellerRunDealRoles={[...sellerRoles, ...adminRoles]}
                    buyerRunDealRoles={[]}
                  >
                    <td className="py-2 px-3 hidden xl:table-cell">
                      <p className="text-sm text-gray-500">
                        {file.permission.name}
                      </p>
                    </td>
                  </DealRoleRestricted>
                  <DealRoleRestricted
                    sellerRunDealRoles={sellSideAdmins}
                    buyerRunDealRoles={allAdmins}
                  >
                    <td className="py-2 px-3 hidden xl:table-cell">
                      <Menu
                        as="div"
                        className="relative flex-none overflow-visible"
                      >
                        <Menu.Button
                          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                            e.stopPropagation();
                          }}
                          className="opacity-0 group-hover:opacity-100 -m-2.5 block p-2.5 text-gray-500 hover:text-gray-900"
                        >
                          <span className="sr-only">Open options</span>
                          <EllipsisVerticalIcon
                            className="h-5 w-5"
                            aria-hidden="true"
                          />
                        </Menu.Button>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                            <Menu.Item>
                              {({ active }) => (
                                <div
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setEditFileId(file.id);
                                  }}
                                  className={classNames(
                                    active ? "bg-gray-50 text-blue-700" : "",
                                    "block px-3 py-1 text-sm leading-6 text-blue-600 cursor-pointer"
                                  )}
                                >
                                  Edit
                                </div>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <div
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setMoveFileId(file.id);
                                  }}
                                  className={classNames(
                                    active ? "bg-gray-50 text-blue-700" : "",
                                    "block px-3 py-1 text-sm leading-6 text-blue-600 cursor-pointer"
                                  )}
                                >
                                  Move
                                </div>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <div
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setDeleteFileId(file.id);
                                  }}
                                  className={classNames(
                                    active ? "bg-gray-50 text-red-700" : "",
                                    "block px-3 py-1 text-sm leading-6 text-red-600 cursor-pointer"
                                  )}
                                >
                                  Delete
                                </div>
                              )}
                            </Menu.Item>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </td>
                  </DealRoleRestricted>
                </tr>
              );
            })}
        </tbody>
      </table>
      <DeleteFile
        open={deleteFileId !== ""}
        dataRoomFileId={deleteFileId}
        onClose={() => {
          setDeleteFileId("");
        }}
      />
      <EditFile
        dataRoomFileId={editFileId}
        open={editFileId !== ""}
        onClose={() => {
          setEditFileId("");
        }}
      />
      <MoveFileModal
        dataRoomFileId={moveFileId}
        open={moveFileId !== ""}
        onClose={() => {
          setMoveFileId("");
        }}
      />
    </>
  );
}

interface ActivityProps {
  dataRoomId: string;
  dataRoomFolderId?: string;
}

function Activity(props: ActivityProps) {
  const client = useGqlClient();
  const activeDealId = useSelector(authSelectors.activeDealId);

  const { data, isLoading, error, refetch } = useDealActivityQuery(client, {
    dealId: activeDealId ?? "",
    input: {
      dataRoomID: props.dataRoomId,
      dataRoomFolderID: props.dataRoomFolderId,
      count: 30,
      cursor: "",
    },
  });

  if (error) {
    return <div>Error</div>;
  }

  if (isLoading || !data) {
    return <Loading />;
  }

  if (data.dealActivity.activity.length === 0) {
    return (
      <div>
        <p className="text-sm text-gray-500">No recent activity</p>
      </div>
    );
  }

  return (
    <ActivityItems
      margin="l 0 0 0"
      items={data.dealActivity.activity}
      mode="verbose"
    />
  );
}

interface DeleteFolderProps {
  onClose: () => void;
  open: boolean;
}

function DeleteFolder(props: DeleteFolderProps) {
  const id = useQuery().get("deleteFolder");

  return (
    <AnimatedModal onClose={props.onClose} open={props.open}>
      <DeleteFolderContent
        id={id}
        onCancel={() => props.onClose()}
        onDelete={() => props.onClose()}
      />
    </AnimatedModal>
  );
}

interface DeleteFolderContentProps {
  id: string | null;
  onCancel: () => void;
  onDelete: () => void;
}

function DeleteFolderContent(props: DeleteFolderContentProps) {
  const client = useGqlClient();
  const queryClient = useQueryClient();
  const folderQuery = useDataRoomFolderQuery(client, {
    id: props.id || "",
  });

  const updateDataRoomFolder = useUpdateDataRoomFolderMutation(client);

  const [data, setData] = useState<DataRoomFolderQuery | null>(null);

  useEffect(() => {
    if (folderQuery.data) {
      setData(folderQuery.data);
    }
  }, [folderQuery.data]);

  if (folderQuery.isPending && !data) {
    return (
      <div>
        <div className="sm:flex sm:items-start">
          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
            <FolderIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
          </div>
          <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
            <Dialog.Title
              as="h3"
              className="text-base font-semibold leading-6 text-gray-900"
            >
              Loading...
            </Dialog.Title>
            <div className="mt-2">
              <p className="text-sm text-gray-500">Please wait</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (folderQuery.error || !data) {
    return (
      <div>
        <div className="sm:flex sm:items-start">
          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
            <ExclamationCircleIcon
              className="h-6 w-6 text-red-600"
              aria-hidden="true"
            />
          </div>
          <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
            <Dialog.Title
              as="h3"
              className="text-base font-semibold leading-6 text-gray-900"
            >
              Something went wrong
            </Dialog.Title>
            <div className="mt-2">
              <p className="text-sm text-gray-500">
                We couldn't load the folder. Please try again.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="sm:flex sm:items-start">
        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
          <FolderIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
        </div>
        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
          <Dialog.Title
            as="h3"
            className="text-base font-semibold leading-6 text-gray-900"
          >
            {`Delete ${data.dataRoomFolder.name}`}
          </Dialog.Title>
          <div className="mt-2">
            <p className="text-sm text-gray-500">
              Are you sure you want to delete the {data.dataRoomFolder.name}{" "}
              folder? All files and folders inside will be deleted.
            </p>
          </div>
        </div>
      </div>
      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <button
          type="button"
          className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
          onClick={() => {
            updateDataRoomFolder.mutate(
              {
                input: {
                  id: data.dataRoomFolder.id,
                  deletedAt: getUnixTime(new Date()),
                },
              },
              {
                onSuccess: () => {
                  queryClient
                    .invalidateQueries({
                      queryKey: [
                        "DataRoomFolder",
                        {
                          id:
                            data.dataRoomFolder.parentFolders.length > 1
                              ? data.dataRoomFolder.parentFolders[1].id
                              : data.dataRoomFolder.id,
                        },
                      ],
                    })
                    .then(() => {
                      props.onDelete();
                    });

                  toasts.success(`${data.dataRoomFolder.name} deleted`);
                },
              }
            );
          }}
        >
          {updateDataRoomFolder.isPending ? "Deleting..." : "Delete"}
        </button>
        <button
          type="button"
          className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
          onClick={() => {
            props.onCancel();
          }}
        >
          Cancel
        </button>
      </div>
    </div>
  );
}
