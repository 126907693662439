import { CheckCircleIcon, FolderIcon } from "@heroicons/react/20/solid";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Loading from "../../components/Loading";
import {
  GranularAccess as GranularAccessGql,
  AccessQuery,
  DealFirmGroupType,
  DataRoomPermission,
  useAllDataRoomFoldersQuery,
  AllDataRoomFoldersQuery,
  AccessType,
} from "../../graphql/generated";
import useGqlClient from "../../hooks/useGqlClient";
import { authSelectors } from "../../store/auth/selector";
import { classNames } from "../../utils/cn";
import { GranularAccess, accessTypeToOption } from ".";
import { FileIcon } from "../../components/FileIcon";
import Dropdown from "../../components/tailwind/Dropdown";
import {
  DealRoleRestricted,
  buyerAdmins,
  sellSideAdmins,
} from "../../components/DealRoleRestricted";

export function EditDataRoomPermissions(props: {
  initialGranularAccess: GranularAccessGql;
  initialDataRoomPermission: AccessQuery["deal"]["dataRoom"]["permissions"][0];
  dataRoomPermissions: AccessQuery["deal"]["dataRoom"]["permissions"];
  firmGroupType: DealFirmGroupType;
  firmGroupId: string;
  onGranularAccessChange: (granularAccess: GranularAccess) => void;
  granularAccess: GranularAccess;
  overrideDataRoomAccess: boolean;
  onOverrideDataRoomAccessChange: (checked: boolean) => void;
  showPermissions: boolean;
}) {
  const activeDealId = useSelector(authSelectors.activeDealId);

  const [selectedDataRoomPermission, setSelectedDataRoomPermission] =
    useState<DataRoomPermission>(props.initialDataRoomPermission);

  const [searchTerm, setSearchTerm] = useState("");

  const client = useGqlClient();
  const allFoldersQuery = useAllDataRoomFoldersQuery(client, {
    dealId: activeDealId ? activeDealId : "",
  });

  function handleFileToggle(
    folder: AllDataRoomFoldersQuery["deal"]["dataRoom"]["allFolders"][0],
    fileId: string,
    accessType: AccessType
  ) {
    const newPermissions = { ...props.granularAccess };
    newPermissions.files[fileId].accessType = accessType;

    if (
      accessType === AccessType.View ||
      accessType === AccessType.Download ||
      accessType === AccessType.Write
    ) {
      newPermissions.folders[folder.id].accessType = AccessType.View;
      newPermissions.folders[folder.id].parentFolders.forEach(
        (parentFolderId) => {
          newPermissions.folders[parentFolderId].accessType = AccessType.View;
        }
      );
    }

    props.onGranularAccessChange(newPermissions);
  }

  function handleFolderToggle(
    folder: AllDataRoomFoldersQuery["deal"]["dataRoom"]["allFolders"][0],
    accessType: AccessType
  ) {
    const newPermissions = { ...props.granularAccess };
    newPermissions.folders[folder.id].accessType = accessType;

    if (accessType === AccessType.View) {
      newPermissions.folders[folder.id].parentFolders.forEach(
        (parentFolderId) => {
          newPermissions.folders[parentFolderId].accessType = AccessType.View;
        }
      );
    }

    if (accessType === AccessType.NoAccess) {
      Object.keys(newPermissions.folders).forEach((folderId) => {
        if (
          newPermissions.folders[folderId].parentFolders.includes(folder.id)
        ) {
          newPermissions.folders[folderId].accessType = AccessType.NoAccess;
          Object.keys(newPermissions.files).forEach((fileId) => {
            if (newPermissions.files[fileId].folderId === folderId) {
              newPermissions.files[fileId].accessType = AccessType.NoAccess;
            }
          });
        }
      });

      Object.keys(newPermissions.files).forEach((fileId) => {
        if (newPermissions.files[fileId].folderId === folder.id) {
          newPermissions.files[fileId].accessType = AccessType.NoAccess;
        }
      });
    }

    props.onGranularAccessChange(newPermissions);
  }

  useEffect(() => {
    if (!allFoldersQuery.data) {
      return;
    }

    const permissions: GranularAccess = {
      folders: {},
      files: {},
    };

    allFoldersQuery.data.deal.dataRoom.allFolders.forEach((folder) => {
      const dealFirmFolder = props.initialGranularAccess.folders.find(
        (dfa) => dfa.id === folder.id
      );

      permissions.folders[folder.id] = {
        accessType: dealFirmFolder ? dealFirmFolder.type : AccessType.NoAccess,
        parentFolders: folder.parentFolders.map((f) => f.id),
      };

      folder.files.forEach((file) => {
        const dealFirmFile = props.initialGranularAccess.files.find(
          (dfa) => dfa.id === file.id
        );
        permissions.files[file.id] = {
          accessType: dealFirmFile ? dealFirmFile.type : AccessType.NoAccess,
          dataRoomPermission: file.permission,
          folderId: folder.id,
        };
      });
    });

    props.onGranularAccessChange(permissions);
  }, [allFoldersQuery.data]);

  if (allFoldersQuery.error) {
    return <div>Something went wrong</div>;
  }

  if (allFoldersQuery.isPending || !allFoldersQuery.data) {
    return <Loading />;
  }

  return (
    <div>
      <div>
        <DealRoleRestricted
          sellerRunDealRoles={sellSideAdmins}
          buyerRunDealRoles={buyerAdmins}
        >
          {props.showPermissions ? (
            <div className="mt-4">
              <p className="font-semibold text-gray-600 text-sm">
                Permission level
              </p>
              <p className="text-sm text-gray-500 leading-tight">
                This is set at the group level
              </p>
              <div className="mt-2 flex gap-x-3">
                {props.dataRoomPermissions.map((permission) => {
                  return (
                    <div
                      key={permission.id}
                      className={classNames(
                        "p-2 rounded-md border-2 relative  group",
                        props.overrideDataRoomAccess
                          ? "border-gray-300 opacity-60"
                          : "",
                        !props.overrideDataRoomAccess &&
                          permission.id === selectedDataRoomPermission.id
                          ? "border-indigo-500"
                          : "",
                        !props.overrideDataRoomAccess &&
                          permission.accessLevel <
                            selectedDataRoomPermission.accessLevel
                          ? "border-indigo-500 opacity-60"
                          : "",
                        !props.overrideDataRoomAccess &&
                          permission.accessLevel >
                            selectedDataRoomPermission.accessLevel
                          ? "opacity-50 "
                          : ""
                      )}
                    >
                      <div className="flex items-center justify-between">
                        <p className="text-sm font-semibold text-gray-800">
                          {permission.name}
                        </p>
                        {permission.id === selectedDataRoomPermission.id ||
                        permission.accessLevel <
                          selectedDataRoomPermission.accessLevel ? (
                          <CheckCircleIcon
                            className={`w-4 h-4 ${
                              props.overrideDataRoomAccess
                                ? "text-gray-500"
                                : "text-indigo-500"
                            }`}
                          />
                        ) : null}
                      </div>
                      <p className="leading-tight mt-1 text-sm text-gray-500">
                        {permission.description}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : null}
        </DealRoleRestricted>

        <div className="mt-3 relative flex items-start">
          <div className="flex h-6 items-center">
            <input
              id="granularAccess"
              aria-describedby="granularAccess-description"
              name="granularAccess"
              type="checkbox"
              checked={props.overrideDataRoomAccess}
              onChange={(e) => {
                props.onOverrideDataRoomAccessChange(e.currentTarget.checked);
              }}
              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
            />
          </div>
          <div className="ml-3 text-sm leading-6">
            <label
              htmlFor="granularAccess"
              className="font-medium text-gray-900 leading-tight cursor-pointer"
            >
              Restrict Data Room access
            </label>
            <p
              id="granularAccess-description"
              className="text-gray-500 leading-tight"
            >
              Limit access to specific files and folders
            </p>
          </div>
        </div>
      </div>

      {props.overrideDataRoomAccess ? (
        <>
          <p className="font-semibold text-gray-600 text-sm  mt-4">
            Restricted Data Room access
          </p>
          <p className="text-sm text-gray-500 leading-tight">
            Users will only be able to view the files and folders you select
            here
          </p>
          <div className="mt-2 rounded-md border-gray-200 border overflow-y-hidden">
            <div className="w-full">
              <input
                type="text"
                className="px-3 py-1.5 text-sm focus:ring-0 border-b border-x-0 border-t-0 border-gray-200 focus:border-gray-200"
                placeholder="Search..."
                value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(e.currentTarget.value);
                }}
              />
            </div>
            <div className="overflow-x-visible relative overflow-y-scroll scrollbar-thin h-80">
              {allFoldersQuery.data.deal.dataRoom.allFolders.map((folder) => {
                if (
                  folder.dealFirmGroup &&
                  props.firmGroupId !== folder.dealFirmGroup.id
                ) {
                  return null;
                }

                return (
                  <FolderContent
                    key={folder.id}
                    folder={folder}
                    permissions={props.granularAccess}
                    onToggle={(accessType) => {
                      handleFolderToggle(folder, accessType);
                    }}
                    onToggleFile={(fileId, accessType) => {
                      handleFileToggle(folder, fileId, accessType);
                    }}
                    searchTerm={searchTerm}
                  />
                );
              })}
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
}

function FolderContent(props: {
  folder: AllDataRoomFoldersQuery["deal"]["dataRoom"]["allFolders"][0];
  permissions: GranularAccess;
  onToggle: (accessType: AccessType) => void;
  onToggleFile(fileId: string, accessType: AccessType): void;
  searchTerm: string;
}) {
  const [showChildren, setShowChildren] = useState(true);
  const folderAccessType = props.permissions.folders[props.folder.id]
    ? props.permissions.folders[props.folder.id].accessType
    : AccessType.NoAccess;

  if (props.searchTerm.length > 2) {
    if (
      !props.folder.name
        .toLowerCase()
        .includes(props.searchTerm.toLowerCase()) &&
      props.folder.files.filter((file) =>
        file.name.toLowerCase().includes(props.searchTerm.toLowerCase())
      ).length === 0
    ) {
      return null;
    }
  }

  return (
    <div className="">
      <div
        className={classNames(
          "items-center  hover:bg-gray-200/70 px-2 flex py-1 justify-between cursor-pointer",
          folderAccessType !== AccessType.NoAccess
            ? ""
            : "opacity-50 hover:opacity-100"
        )}
      >
        <div className="flex items-center">
          <FolderIcon className="w-5 h-5 text-blue-700/70" />
          <p className="font-semibold mt-1 ml-1 select-none text-gray-800 text-sm">
            {props.folder.parentFolders.length > 0
              ? `${props.folder.parentFolders
                  .map((f) => (f.name ? f.name : "Home"))
                  .reverse()
                  .join(" / ")}`
              : "Home /"}
          </p>
        </div>
        <Dropdown
          variant="text"
          selectedOption={
            folderAccessType === AccessType.View
              ? { label: "View", value: AccessType.View }
              : { label: "No access", value: AccessType.NoAccess }
          }
          onSelect={(e) => {
            props.onToggle(e.value as AccessType);
          }}
          options={[
            { label: "View", value: AccessType.View },
            { label: "No access", value: AccessType.NoAccess },
          ]}
        />
      </div>
      {showChildren ? (
        <div className="">
          <div>
            {props.folder.files
              .filter((file) =>
                props.searchTerm.length > 2
                  ? file.name
                      .toLowerCase()
                      .includes(props.searchTerm.toLowerCase())
                  : true
              )
              .map((file) => {
                const fileAccessType = props.permissions.files[file.id]
                  ? props.permissions.files[file.id].accessType
                  : AccessType.NoAccess;

                return (
                  <div
                    key={file.id}
                    className={`cursor-pointer px-2 justify-between hover:bg-gray-200/70  items-center flex  py-0.5 ${
                      fileAccessType === AccessType.NoAccess
                        ? "opacity-50 hover:opacity-100"
                        : ""
                    }`}
                  >
                    <div className="ml-2 flex items-center">
                      <FileIcon fileType={file.fileType} />
                      <p className="ml-1 select-none font-semibold text-sm text-gray-600">
                        {file.name}
                        <span className="text-xs ml-1 font-normal text-gray-500">
                          {file.permission.name}
                        </span>
                      </p>
                    </div>
                    <Dropdown
                      variant="text"
                      selectedOption={accessTypeToOption(fileAccessType)}
                      onSelect={(e) => {
                        props.onToggleFile(file.id, e.value as AccessType);
                      }}
                      options={[
                        { label: "Download", value: AccessType.Download },
                        { label: "View", value: AccessType.View },
                        { label: "No access", value: AccessType.NoAccess },
                      ]}
                    />
                  </div>
                );
              })}
          </div>
        </div>
      ) : null}
    </div>
  );
}
