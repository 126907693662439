import React from "react";
import { classNames } from "../../utils/cn";
import { Spinner } from "../icons/Spinner";
import { withMarginTw } from "../../styles/withMargin";

interface Props {
  variant: "neutral" | "positive" | "danger";
  text: string;
  onClick?: () => void;
  icon?: React.ElementType;
  isLoading?: boolean;
  isDisabled?: boolean;
  loadingText?: string;
  type?: "submit" | "reset" | "button";
  margin?: string;
  keyboardShortcut?: string;
  size?: "s";
}

// Update your component to use forwardRef
export const Button = React.forwardRef<HTMLButtonElement, Props>(
  (
    {
      variant,
      onClick,
      text,
      icon: Icon,
      isLoading,
      isDisabled,
      loadingText,
      type,
      margin,
      keyboardShortcut,
      size,
    },
    ref
  ) => {
    const cacledMargin = withMarginTw({ margin });
    return (
      <button
        ref={ref}
        type={type}
        className={classNames(
          cacledMargin,
          isDisabled ? "cursor-not-allowed opacity-70" : "",
          "relative inline-flex items-center gap-x-2 rounded-md text-sm font-semibold ring-1 ring-inset shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2",
          variant === "neutral"
            ? "bg-white text-gray-600 ring-gray-300 hover:bg-gray-50 focus-visible:outline-gray-600"
            : "",
          variant === "positive"
            ? "bg-persian-800/90 text-white ring-persian-300 hover:bg-persian-700/90 focus-visible:outline-persian-800"
            : "",
          variant === "danger"
            ? "bg-red-600 ring-red-500 text-white hover:bg-red-500 focus-visible:outline-red-600"
            : "",
          isLoading ? "cursor-not-allowed" : "",
          size === "s" ? "text-sm px-2.5 py-1.5" : " px-3.5 py-2.5"
        )}
        onClick={() => {
          if (isDisabled || isLoading) {
            return;
          }
          if (onClick) {
            onClick();
          }
        }}
        disabled={isLoading} // Optionally disable button when loading
      >
        {isLoading ? (
          <div className="w-5 h-5">
            {/* Assuming Spinner is a component you have that accepts a color prop */}
            <Spinner color={variant === "neutral" ? "gray" : "white"} />
          </div>
        ) : Icon ? (
          <Icon className="w-5 h-5" />
        ) : null}
        {isLoading && loadingText ? loadingText : text}
        {keyboardShortcut ? (
          <kbd
            className={classNames(
              "pointer-events-none font-sans absolute -top-4 -right-2 mx-auto shadow z-10 text-xs justify-center border border-gray-200 bg-white rounded-lg text-gray-500 flex items-center px-2 py-1 font-semibold"
            )}
          >
            {keyboardShortcut}
          </kbd>
        ) : null}
      </button>
    );
  }
);

Button.displayName = "Button";
