import { useQueryClient } from "@tanstack/react-query";
import { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation, match } from "react-router-dom";
import * as H from "history";
import { authSelectors } from "../store/auth/selector";
import {
  DealFirmGroupType,
  DealRole,
  DealThreadMessage,
  DealThreadMessageRole,
  DealThreadMessageStatus,
  useActiveDealAccountQuery,
  useCreateDealThreadMessageMutation,
  useCreateDealThreadMutation,
  useDealQuery,
} from "../graphql/generated";
import useGqlClient from "../hooks/useGqlClient";
import { WithMarginProp } from "../styles/withMargin";
import { classNames } from "../utils/cn";
import {
  adminRoles,
  allAdmins,
  allRoles,
  buyerAdmins,
  buyerRoles,
  DealRoleRestricted,
  sellerRoles,
  sellSideAdmins,
} from "./DealRoleRestricted";
import {
  BookOpenCheckIcon,
  BotIcon,
  ChartBarIcon,
  CircleAlertIcon,
  CogIcon,
  FileSearch2Icon,
  FilesIcon,
  FolderIcon,
  Grid2x2Icon,
  LockKeyholeIcon,
  MessageCircleQuestionIcon,
  PlusIcon,
  PocketKnifeIcon,
  UserPlusIcon,
  UsersIcon,
} from "lucide-react";

import CompanyLogo from "./CompanyLogo";
import { Dialog, Transition } from "@headlessui/react";
import {
  ArrowLeftCircleIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/solid";
import { Button } from "./tailwind/Button";
import { toasts } from "./toasts/toasts";
import { actions } from "../store/assistant/slice";
import { useWebSocket } from "../contexts/websockets";
import { assistantSelectors } from "../store/assistant/selector";
import { AppState } from "../store";
import { animated } from "react-spring";
import { useTransition } from "react-spring";
import ReactMarkdown from "react-markdown";
import { Loader } from "./Loading";
import { Pills } from "./Pills";
import { FilePill } from "./FilePill";
import { v4 as uuid } from "uuid";

export function DealNavigation() {
  const [showAssistant, setShowAssistant] = useState(false);
  const location = useLocation();

  const client = useGqlClient();
  const activeDealId = useSelector(authSelectors.activeDealId);
  const dealQuery = useDealQuery(client, {
    id: activeDealId ? activeDealId : "",
  });

  const activeDeal = useSelector(authSelectors.activeDeal);
  return (
    <div className="sticky top-0 z-20">
      <div className="transition-colors duration-300 ease-in-out bg-gray-200 p-4 pb-0 ">
        <div className="flex items-center justify-between">
          <div className="flex  items-center gap-3 mb-2 px-2">
            <div style={{ position: "relative" }}>
              <CompanyLogo
                logo={activeDeal ? activeDeal.company.logo : undefined}
                withShadow={false}
                borderSize={"2"}
                size={32}
                name={activeDeal ? activeDeal.company.name : ""}
                bgColor={activeDeal ? activeDeal.company.logoColor : "#000"}
              />
            </div>
            <div className="flex flex-col">
              <p className="font-bold text-gray-800 truncate">
                {activeDeal ? activeDeal.company.name : ""}
              </p>
              <p className="text-gray-700 font-semi text-xs">
                {dealQuery.data &&
                dealQuery.data.deal.activeDealAccount.dealFirmGroup &&
                dealQuery.data.deal.activeDealAccount.dealFirmGroup.type ===
                  DealFirmGroupType.Buyer
                  ? "Buy side"
                  : "Sell side"}
              </p>
            </div>
          </div>
          <DealRoleRestricted
            sellerRunDealRoles={[...sellerRoles, ...adminRoles]}
            buyerRunDealRoles={[...allRoles]}
          >
            <button
              onClick={() => {
                setShowAssistant(true);
              }}
              className="rounded-full flex items-center gap-x-2 font-semibold ring-1 shadow-sm ring-persian-900 bg-white text-sm text-persian-800 px-2 py-1"
            >
              <BotIcon className="w-4 h-4" />
              Assistant
            </button>
          </DealRoleRestricted>
        </div>
        <div className="flex gap-x-2">
          <Link margin="0 0 xs 0" to="/dashboard">
            <div className="flex items-center space-x-2">
              <Grid2x2Icon className="w-4 h-4" />
              <span className="text-sm font-semibold">Dashboard</span>
            </div>
          </Link>
          <DealRoleRestricted
            buyerRunDealRoles={[]}
            sellerRunDealRoles={[...sellerRoles, ...adminRoles]}
          >
            <Link margin="0 0 xs 0" to="/buyers">
              <div className="flex items-center space-x-2">
                <UsersIcon className="w-4 h-4 " />
                <span className="text-sm font-semibold">Buyers</span>
              </div>
            </Link>
          </DealRoleRestricted>
          {/* {location.pathname.startsWith("/buyers") ? (
                <div style={{ marginLeft: 12 }}>
                  <Link margin="0 0 xs 0" to="/buyers/dd-checklists">
                    <div className="flex items-center space-x-2">
                      <ClipboardDocumentListIcon className="w-4 h-4 text-white" />
                      <span className="text-sm font-semibold">
                        DD Checklists
                      </span>
                    </div>
                  </Link>
                </div>
              ) : null} */}

          <Link margin="0 0 xs 0" to="/questions">
            <div className="flex items-center space-x-2">
              <MessageCircleQuestionIcon className="w-4 h-4 " />
              <span className="text-sm font-semibold">Questions</span>
            </div>
          </Link>

          <DealRoleRestricted
            sellerRunDealRoles={[...sellerRoles, ...adminRoles]}
            buyerRunDealRoles={[...buyerRoles, ...adminRoles]}
          >
            <Link margin="0 0 xs 0" to="/toolkit">
              <div className="flex items-center space-x-2">
                <PocketKnifeIcon className="w-4 h-4 " />
                <span className="text-sm font-semibold">Toolkit</span>
              </div>
            </Link>
          </DealRoleRestricted>
          {/* <Link margin="0 0 s 0" to="/due-diligence">
                <div className="flex items-center space-x-2">
                  <MagnifyingGlassCircleIcon className="w-5 h-5 text-white" />
                  <p className="text-sm ">Due Diligence Checklist</p>
                </div>
              </Link> */}

          <Link margin="0 0 xs 0" to="/deal/documents">
            <div className="flex items-center space-x-2 w-full">
              <FolderIcon className="h-4 w-4 " />
              <div className="flex items-center justify-between w-full">
                <span className="text-sm font-semibold">Documents</span>
              </div>
            </div>
          </Link>

          <DealRoleRestricted
            sellerRunDealRoles={[
              DealRole.BuyerAdmin,
              DealRole.DealAdmin,
              DealRole.SellerAdmin,
              DealRole.DealOwner,
            ]}
            buyerRunDealRoles={[
              DealRole.DealAdmin,
              DealRole.DealOwner,
              DealRole.BuyerAdmin,
              DealRole.SellerAdmin,
            ]}
          >
            <Link margin="0 0 xs 0" to="/deal/access">
              <div className="flex items-center space-x-2">
                <LockKeyholeIcon className="w-4 h-4 " />
                <span className="text-sm font-semibold">Access</span>
              </div>
            </Link>
          </DealRoleRestricted>
          <DealRoleRestricted
            sellerRunDealRoles={sellSideAdmins}
            buyerRunDealRoles={buyerAdmins}
          >
            <Link margin="0 0 xs 0" to={`/deal/settings/exports`}>
              <div className="flex items-center space-x-2">
                <CogIcon className="w-4 h-4 " />
                <span className="text-sm font-semibold">Settings</span>
              </div>
            </Link>
          </DealRoleRestricted>
        </div>
      </div>
      {location.pathname.startsWith("/deal/documents") ? (
        <DealRoleRestricted
          sellerRunDealRoles={sellSideAdmins}
          buyerRunDealRoles={allAdmins}
        >
          <SecondaryNavBar>
            <Link
              margin="0 0 0 0"
              to="/deal/documents"
              mode="secondary"
              isActive={(match) => {
                if (!match) {
                  return false;
                }

                if (location.pathname.endsWith("/activity")) {
                  return false;
                }

                return match.url.startsWith("/deal/documents");
              }}
            >
              <FilesIcon className="w-4 h-4 " />
              <span className="text-sm font-semibold">Documents</span>
            </Link>
            <Link
              margin="0 0 0 0"
              to="/deal/documents/activity"
              mode="secondary"
            >
              <ChartBarIcon className="w-4 h-4 " />
              <span className="text-sm font-semibold">Activity</span>
            </Link>
          </SecondaryNavBar>
        </DealRoleRestricted>
      ) : null}

      {location.pathname.startsWith("/toolkit") ? (
        <DealRoleRestricted
          sellerRunDealRoles={sellSideAdmins}
          buyerRunDealRoles={allAdmins}
        >
          <SecondaryNavBar>
            <Link
              margin="0 0 0 0"
              to="/toolkit/error-checking"
              mode="secondary"
            >
              <CircleAlertIcon className="w-4 h-4 " />
              <span className="text-sm font-semibold">Error checking</span>
            </Link>
            <Link margin="0 0 0 0" to="/toolkit/deep-dive" mode="secondary">
              <BookOpenCheckIcon className="w-4 h-4 " />
              <span className="text-sm font-semibold">Document history</span>
            </Link>
            <Link margin="0 0 0 0" to="/toolkit/deep-search" mode="secondary">
              <FileSearch2Icon className="w-4 h-4 " />
              <span className="text-sm font-semibold">
                Document smart search
              </span>
            </Link>
          </SecondaryNavBar>
        </DealRoleRestricted>
      ) : null}

      {location.pathname.startsWith("/deal/access") ? (
        <DealRoleRestricted
          sellerRunDealRoles={sellSideAdmins}
          buyerRunDealRoles={buyerAdmins}
        >
          <SecondaryNavBar>
            <Link margin="0 0 0 0" to="/deal/access/group" mode="secondary">
              <UsersIcon className="w-4 h-4 " />
              <span className="text-sm font-semibold">Groups</span>
            </Link>
            <Link margin="0 0 0 0" to="/deal/access/requests" mode="secondary">
              <UserPlusIcon className="w-4 h-4 " />
              <span className="text-sm font-semibold">Requests</span>
            </Link>
          </SecondaryNavBar>
        </DealRoleRestricted>
      ) : null}

      {location.pathname.startsWith("/deal/settings") ? (
        <DealRoleRestricted
          sellerRunDealRoles={sellSideAdmins}
          buyerRunDealRoles={[...adminRoles, ...buyerRoles]}
        >
          <SecondaryNavBar>
            <Link margin="0 0 0 0" to="/deal/settings/exports" mode="secondary">
              <CogIcon className="w-4 h-4 " />
              <span className="text-sm font-semibold">Exports</span>
            </Link>
          </SecondaryNavBar>
        </DealRoleRestricted>
      ) : null}

      <SlideOver open={showAssistant} onClose={() => setShowAssistant(false)} />
    </div>
  );
}

function SecondaryNavBar(props: { children: React.ReactNode }) {
  return (
    <div className="bg-gray-100 border-y border-gray-300 gap-x-8 py-0  flex items-center px-7">
      {props.children}
    </div>
  );
}

const Link = ({
  children,
  size,
  onClick,
  mode,
  isActive,
  ...props
}: {
  children: React.ReactNode;
  size?: string;
  mode?: "secondary" | "primary";
  onClick?: () => void;
  isActive?<Params extends { [K in keyof Params]?: string }>(
    match: match<Params> | null,
    location: H.Location<H.LocationState>
  ): boolean;
} & WithMarginProp & {
    to: string;
    exact?: boolean;
  }) => {
  if (mode && mode === "secondary") {
    return (
      <NavLink
        isActive={isActive}
        {...props}
        onClick={onClick}
        key={props.to}
        className={(isActive) => {
          return classNames(
            size === "s" ? "text-sm px-0" : "text-base",
            "gap-x-2 -mb-px py-1.5 my-1.5 text-gray-700 font-semibold border-b-2 flex items-center",
            isActive
              ? " text-gray-700 border-persian-950"
              : "text-gray-700 opacity-70 hover:opacity-100 hover:border-persian-950 border-transparent"
          );
        }}
      >
        {children}
      </NavLink>
    );
  }

  return (
    <NavLink
      {...props}
      key={props.to}
      className={(isActive) => {
        return classNames(
          size === "s" ? "text-sm px-0" : "text-base",
          "gap-x-2 px-3 py-1.5 my-1.5 hover:bg-gray-300/80 font-semibold rounded-md flex items-center",
          isActive ? "bg-gray-300/80 text-gray-800" : "text-gray-800"
        );
      }}
    >
      {children}
    </NavLink>
  );
};

function SlideOver(props: { onClose: () => void; open: boolean }) {
  const activeDealId = useSelector(authSelectors.activeDealId);
  const client = useGqlClient();
  const createDealThread = useCreateDealThreadMutation(client);
  const dispatch = useDispatch();
  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={props.onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-300 sm:duration-500"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-300 sm:duration-500"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col overflow-y-scroll no-scrollbar bg-white border-b border-gray z-50 pt-6 shadow-xl">
                    <div className="px-1 sm:px-3 shadow pb-4">
                      <div className="flex items-start  justify-between">
                        <div className="flex items-center gap-x-2">
                          <button
                            type="button"
                            className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            onClick={() => props.onClose()}
                          >
                            <span className="absolute -inset-2.5" />
                            <span className="sr-only">Close panel</span>
                            <ArrowLeftCircleIcon
                              className="h-6 w-6"
                              aria-hidden="true"
                            />
                          </button>
                          <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                            Assistant
                          </Dialog.Title>
                        </div>
                        <div className="ml-3 flex h-7 items-center">
                          <Button
                            icon={PlusIcon}
                            variant="neutral"
                            text="New chat"
                            size="s"
                            onClick={() => {
                              if (!activeDealId) return;

                              createDealThread.mutate(
                                {
                                  input: {
                                    dealID: activeDealId,
                                  },
                                },
                                {
                                  onError: () => {
                                    toasts.error("Failed to create chat");
                                  },
                                  onSuccess: (data) => {
                                    dispatch(
                                      actions.setActiveDealThreadId(
                                        data.createDealThread.id
                                      )
                                    );

                                    for (const message of data.createDealThread
                                      .messages) {
                                      dispatch(
                                        actions.addDealThreadMessage({
                                          dealThreadId:
                                            data.createDealThread.id,
                                          message: message as DealThreadMessage,
                                        })
                                      );
                                    }
                                  },
                                }
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="relative overflow-y-scroll  flex-1 ">
                      <Chat />
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

function Chat() {
  const [newMessage, setNewMessage] = useState("");

  const { subscribeToTopic, unsubscribeFromTopic } = useWebSocket();

  const activeDealId = useSelector(authSelectors.activeDealId);
  const client = useGqlClient();
  const queryClient = useQueryClient();
  const activeDealAccount = useActiveDealAccountQuery(client, {
    id: activeDealId ?? "",
  });

  const activeDealThreadId = useSelector(assistantSelectors.activeDealThreadId);

  const createDealThread = useCreateDealThreadMutation(client);
  const createDealThreadMessage = useCreateDealThreadMessageMutation(client);

  const dealThreadMessages = useSelector((state: AppState) =>
    assistantSelectors.dealThreadMessages(state, activeDealThreadId ?? "")
  );

  const lastMessage =
    dealThreadMessages && dealThreadMessages.length > 0
      ? dealThreadMessages[dealThreadMessages.length - 1]
      : undefined;
  const [chatDisabled, setChatDisabled] = useState(false);

  useEffect(() => {
    if (!lastMessage) {
      return;
    }

    if (
      lastMessage.role === DealThreadMessageRole.User &&
      (lastMessage.status === DealThreadMessageStatus.Sending ||
        lastMessage.status === DealThreadMessageStatus.Sent)
    ) {
      setChatDisabled(true);
      return;
    }

    setChatDisabled(false);
    textAreaRef?.current?.focus();
  }, [lastMessage?.status, lastMessage?.role]);

  const scrollRef = useRef<HTMLDivElement>(null);
  const createCommentRef = useRef<HTMLButtonElement>(null);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const dispatch = useDispatch();

  useEffect(() => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [dealThreadMessages]);

  useEffect(() => {
    if (!activeDealAccount.data) return;
    if (!scrollRef.current) return;

    if (activeDealAccount.data.deal.activeDealAccount.dealThread) {
      dispatch(
        actions.setActiveDealThreadId(
          activeDealAccount.data.deal.activeDealAccount.dealThread.id
        )
      );

      for (const message of activeDealAccount.data.deal.activeDealAccount
        .dealThread.messages) {
        dispatch(
          actions.addDealThreadMessage({
            dealThreadId:
              activeDealAccount.data.deal.activeDealAccount.dealThread.id,
            message: message as DealThreadMessage,
          })
        );
      }

      scrollRef.current?.scrollIntoView({ behavior: "smooth" });

      return;
    }

    createDealThread.mutate(
      {
        input: {
          dealID: activeDealId ?? "",
        },
      },
      {
        onError: (e) => {
          console.log(e);
        },
        onSuccess: (d) => {
          queryClient.invalidateQueries({
            queryKey: ["ActiveDealAccount", { id: activeDealId ?? "" }],
          });

          dispatch(actions.setActiveDealThreadId(d.createDealThread.id));
        },
      }
    );
  }, [activeDealAccount.data, scrollRef.current]);

  useEffect(() => {
    if (!activeDealThreadId) {
      return;
    }

    subscribeToTopic(`deal_thread:${activeDealThreadId}`);

    return () => {
      unsubscribeFromTopic(`deal_thread:${activeDealThreadId}`);
    };
  }, [activeDealThreadId, subscribeToTopic, unsubscribeFromTopic]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      if (createCommentRef.current) {
        createCommentRef.current.click();
      }
    }
  };

  const transitions = useTransition(dealThreadMessages, {
    from: { transform: "translate3d(0,40px,0)", opacity: 0 },
    enter: { transform: "translate3d(0,0px,0)", opacity: 1 },
    leave: { transform: "translate3d(0,-40px,0)", opacity: 0 },
    keys: (message) => (message as any).id,
  });

  return (
    <div className="flex h-full flex-col">
      <div className="flex-1 overflow-y-scroll bg-gray-50 px-4">
        {transitions((style, message) => {
          if (!message.content) {
            return null;
          }
          if (message.role === DealThreadMessageRole.User) {
            return (
              <animated.div
                style={style}
                key={message.id}
                className={classNames(
                  "flex items-center justify-end space-x-2",
                  message.status === DealThreadMessageStatus.Sending ||
                    message.status === DealThreadMessageStatus.Failed
                    ? "opacity-50"
                    : "opacity-1"
                )}
              >
                <div className="flex items-center gap-x-2">
                  {message.status === DealThreadMessageStatus.Failed ? (
                    <ExclamationTriangleIcon className="w-5 h-5 text-red-500" />
                  ) : null}
                  <div className="bg-persian-100/70 max-w-xs shadow rounded-md p-2 my-2">
                    <p className="text-sm text-gray-600">
                      <ReactMarkdown>
                        {message.content.text.value}
                      </ReactMarkdown>
                    </p>
                  </div>
                </div>
              </animated.div>
            );
          }

          return (
            <animated.div
              style={style}
              key={message.id}
              className="flex items-center justify-start space-x-2"
            >
              <div className="bg-gray-50 max-w-xs shadow rounded-md p-2 my-2">
                <p className="text-sm text-gray-600">
                  {message.status === DealThreadMessageStatus.Thinking ? (
                    <div className="flex">
                      <Loader fill="#6B7280" style={{ width: 30, height: 6 }} />
                    </div>
                  ) : (
                    <>
                      <ReactMarkdown>
                        {message.content.text.value}
                      </ReactMarkdown>
                    </>
                  )}
                </p>
                <div className="mt-2">
                  {message.content.quotes
                    ? message.content.quotes.map((quote) => {
                        return (
                          <div
                            className="p-3 bg-white border my-1 border-gray-200 rounded-md"
                            key={quote.quote}
                          >
                            <p className="text-sm text-gray-600">
                              {quote.quote}
                            </p>
                          </div>
                        );
                      })
                    : null}
                </div>
                {message.content.files && message.content.files.length > 0 ? (
                  <Pills>
                    {message.content.files.map((file) => {
                      return (
                        <FilePill
                          key={file.file.id}
                          id={file.file.id}
                          name={file.file.name}
                          type={file.file.fileType}
                          showDetailsCard={false}
                          pageIndex={
                            file.pageIndex ? file.pageIndex : undefined
                          }
                          rectsOnPage={
                            file.rectsOnPage ? file.rectsOnPage : undefined
                          }
                        />
                      );
                    })}
                  </Pills>
                ) : null}
                {message.files && message.files.length > 0 ? (
                  <Pills>
                    {message.files.map((file) => {
                      return (
                        <FilePill
                          key={file.id}
                          id={file.id}
                          name={file.name}
                          type={file.fileType}
                          showDetailsCard={false}
                        />
                      );
                    })}
                  </Pills>
                ) : null}
              </div>
            </animated.div>
          );
        })}
        <div ref={scrollRef} />
      </div>

      <div className="p-4 border-t bg-gray-50">
        <div className="relative flex-auto">
          <div
            className={classNames(
              chatDisabled ? "opacity-50 focus-within:ring-gray-300" : "",
              "bg-white overflow-hidden rounded-lg pb-12 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-1 focus-within:ring-indigo-600"
            )}
          >
            <label htmlFor="comment" className="sr-only">
              Add your comment
            </label>
            <textarea
              ref={textAreaRef}
              rows={2}
              name="comment"
              value={newMessage}
              onKeyDown={(e) => {
                handleKeyDown(e);
              }}
              id="comment"
              onChange={(e) => {
                if (chatDisabled) {
                  return;
                }

                setNewMessage(e.currentTarget.value);
              }}
              className={classNames(
                chatDisabled ? "opacity-50" : "",
                "p-4 focus:outline-none block w-full resize-none border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
              )}
              placeholder="Send a message..."
            />
          </div>

          <div className="absolute inset-x-0 bottom-0 flex justify-between py-2 pl-3 pr-2">
            <div className="flex items-center space-x-5"></div>
            <button
              disabled={chatDisabled}
              ref={createCommentRef}
              onClick={() => {
                if (!newMessage) {
                  return;
                }

                const newId = `deal_thread_msg_${uuid()}`;
                const messageToAdd: Partial<DealThreadMessage> = {
                  id: newId,
                  role: DealThreadMessageRole.User,
                  content: {
                    text: {
                      value: newMessage,
                      annotations: [],
                    },
                    quotes: [],
                    files: [],
                    type: "text",
                  },
                  status: DealThreadMessageStatus.Sending,
                };

                dispatch(
                  actions.addDealThreadMessage({
                    dealThreadId: activeDealThreadId ?? "",
                    message: messageToAdd as DealThreadMessage,
                  })
                );

                setNewMessage("");

                createDealThreadMessage.mutate(
                  {
                    input: {
                      id: newId,
                      message: newMessage,
                      dealThreadID: activeDealThreadId ?? "",
                    },
                  },
                  {
                    onError: (e) => {
                      console.log(e);
                      dispatch(
                        actions.updateDealThreadMessageStatus({
                          dealThreadId: activeDealThreadId ?? "",
                          messageId: newId,
                          status: DealThreadMessageStatus.Failed,
                        })
                      );
                    },
                    onSuccess: () => {
                      dispatch(
                        actions.updateDealThreadMessageStatus({
                          dealThreadId: activeDealThreadId ?? "",
                          messageId: newId,
                          status: DealThreadMessageStatus.Sent,
                        })
                      );
                    },
                  }
                );
              }}
              className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              Send
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
