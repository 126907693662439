import React, { useState } from "react";

interface Tab {
  label: string;
  content: React.ReactNode;
  badge?: string;
  icon?: React.ReactNode;
}

const Tabs = ({ tabs }: { tabs: Tab[] }) => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div>
      {/* Tabs Navigation */}
      <div className="flex border-b border-gray-200">
        {tabs.map((tab, index) => (
          <button
            key={index}
            className={`px-4 py-2 -mb-px text-sm font-medium flex items-center gap-x-2 border-b-2 ${
              activeTab === index
                ? "border-persian-500 text-persian-600"
                : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
            }`}
            onClick={() => setActiveTab(index)}
          >
            {tab.icon ? (
              <span className="inline-flex items-center">{tab.icon}</span>
            ) : null}
            {tab.label}
            {tab.badge ? (
              <span
                className={`inline-flex items-center justify-center w-4 h-4 text-xs font-semibold ${
                  index === activeTab
                    ? "text-persian-800 bg-persian-200"
                    : "text-gray-800 bg-gray-200"
                } rounded-full`}
              >
                {tab.badge}
              </span>
            ) : null}
          </button>
        ))}
      </div>

      <div className="">
        {tabs[activeTab] && <div>{tabs[activeTab].content}</div>}
      </div>
    </div>
  );
};

export default Tabs;
