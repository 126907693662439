import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AccountFragmentFragment, DataRoomFile } from "../../graphql/generated";
import { StateError } from "../error";
import { getUnixTime } from "date-fns";

export type AuthState = {
  isLoggedIn: boolean;
  isLoading: boolean;
  accessToken?: string;
  refreshToken?: string;
  error: StateError;
  account?: AccountFragmentFragment;
  activeDealId?: string;
  dealsLastSeen: { [dealId: string]: number };

  // BIG HACK
  staffSheetQuestionAnswer?: {
    file: DataRoomFile;
    answer: string;
  };
};

const initialState: AuthState = {
  isLoggedIn: false,
  isLoading: false,
  error: null,
  dealsLastSeen: {},
};

export const { actions, reducer } = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginSuccess: (
      state,
      action: PayloadAction<{
        tokens: { accessToken: string; refreshToken: string };
        account: AccountFragmentFragment;
      }>
    ) => {
      state.isLoading = false;
      state.isLoggedIn = true;
      state.accessToken = action.payload.tokens.accessToken;
      state.refreshToken = action.payload.tokens.refreshToken;
      state.account = action.payload.account;
      if (action.payload.account.deals.length > 0) {
        state.activeDealId = action.payload.account.deals[0].id;
      }
    },
    loginFailure: (state) => {
      state.isLoading = false;
      state.isLoggedIn = false;
      state.accessToken = undefined;
      state.refreshToken = undefined;
    },
    logout: (state) => initialState,
    refreshAccount: (
      state,
      action: PayloadAction<{ account: AccountFragmentFragment }>
    ) => {
      state.account = action.payload.account;
    },
    setAccessToken: (state, action: PayloadAction<{ token: string }>) => {
      state.accessToken = action.payload.token;
    },
    setActiveDealId(state, action: PayloadAction<{ id: string | undefined }>) {
      state.activeDealId = action.payload.id;

      if (action.payload.id) {
        state.dealsLastSeen[action.payload.id] = getUnixTime(new Date());
      }
    },

    // more hack
    setStaffSheetQuestionAnswer(
      state,
      action: PayloadAction<{
        answer: {
          file: DataRoomFile;
          answer: string;
        };
      }>
    ) {
      state.staffSheetQuestionAnswer = action.payload.answer;
    },
  },
});
