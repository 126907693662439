import {
  DealActivityType,
  DealRole,
  FirmAccountType,
  FirmType,
  Maybe,
} from "../graphql/generated";

// convert all upercase to lowercase and replace underscore with space
export function formatEnum(value: string) {
  if (value === "TODO") {
    return "To do";
  }

  return value
    .toLowerCase()
    .replace(/_/g, " ")
    .replace(/\b\w/g, (c) => c.toUpperCase());
}

export function formatActivityType(type: DealActivityType) {
  switch (type) {
    case DealActivityType.FileCreated:
      return "File created";
    case DealActivityType.FileVersionCreated:
      return "New version uploaded";
    case DealActivityType.FileViewed:
      return "File viewed";
    case DealActivityType.FileDownloaded:
      return "File downloaded";
    default:
      return "Unknown activity";
  }
}

export function formatDealRole(role: DealRole) {
  switch (role) {
    case DealRole.BuyerAdmin:
      return "Buyer Admin";
    case DealRole.BuyerStaff:
      return "Buyer";
    case DealRole.SellerAdmin:
      return "Seller Admin";
    case DealRole.SellerStaff:
      return "Seller";
    case DealRole.DealOwner:
      return "Deal Owner";
    case DealRole.DealAdmin:
      return "Deal Admin";
    default:
      return "Unknown role";
  }
}

export function formatFirmType(type: FirmType) {
  switch (type) {
    case FirmType.Buyer:
      return "Buyer";
    case FirmType.Seller:
      return "Seller";
    case FirmType.LawFirm:
      return "Law firm";
    case FirmType.Accountant:
      return "Accountant";
    case FirmType.Broker:
      return "Broker";
    case FirmType.Other:
      return "Other";
    default:
      return "Unknown firm type";
  }
}

export function formatFirmAccountType(
  type: Maybe<FirmAccountType> | undefined
) {
  switch (type) {
    case FirmAccountType.Admin:
      return "Admin";
    case FirmAccountType.Staff:
      return "Staff";
    default:
      return "Unknown account type";
  }
}
